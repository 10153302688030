export function horizontalScroll(blockId: string) {
  const container = document.getElementById(blockId);
  container?.addEventListener('wheel', (e) => {
    if (e.deltaY > 0) {
      container.scrollLeft += 100;
      e.preventDefault();
    } else {
      container.scrollLeft -= 100;
      e.preventDefault();
    }
  });
}
