import React, { FC, useMemo } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import { IReviewsQueryEdge } from '../../../queries/reviews/types';
import { ReviewCardRating } from '../ReviewCardRating';
import { t } from '../../../i18n';
import { formatIsoDateToHuman } from '../../../utils/date';
import { TLink } from '../../../i18n/TLink';

interface IProps {
  node: IReviewsQueryEdge['node'];
  caseUrl?: string;
  locale: string;
}

const ReviewCard: FC<IProps> = ({ node, caseUrl, locale }) => {
  const {
    frontmatter: {
      title,
      short,
      position,
      reviewer,
      date,
      ratings,
      quote,
      fullUrl,
    },
    html,
  } = node;

  const dateFormatted = useMemo(
    () => formatIsoDateToHuman(date, locale),
    [date, locale]
  );

  return (
    <div className={styles.wrap}>
      <div className={styles.wrap__body}>
        <h1>{title}</h1>

        {!!short && <div className={styles.short}>{short}</div>}

        <div className={styles.position}>{position}</div>

        <div className={styles.reviewer}>
          {reviewer}, {dateFormatted}
        </div>

        {!!quote && (
          <div className={styles.quote}>
            &quot;
            {quote}
            &quot;
          </div>
        )}

        {!!html && (
          <div
            className={styles.html}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        )}
      </div>

      <div className={styles.wrap__controls}>
        <ReviewCardRating
          quality={ratings.quality}
          refer={ratings.refer}
          cost={ratings.cost}
          schedule={ratings.schedule}
        />

        {!!fullUrl && (
          <a
            href={fullUrl}
            target="_blank"
            rel="nofollow noreferrer"
            className={styles.button}
          >
            {t('reviews.read_full')}
          </a>
        )}

        {!!caseUrl && (
          <TLink
            className={classNames(styles.button, styles.button_filled)}
            to={caseUrl}
          >
            {t('reviews.watch_case')}
          </TLink>
        )}
      </div>
    </div>
  );
};

export { ReviewCard };
