import React, { FC, useState } from 'react';
import { ArrowDownIcon } from '../../../../common/icons/basic';
import styles from './styles.module.scss';

export type IFaq = {
  title: string;
  desc: string;
}[];

const Accordion: FC<{ title: string; desc: string }> = ({ title, desc }) => {
  const [open, setOpen] = useState(false);
  const toggle = () => setOpen(!open);
  const rotateStyles = open ? { transform: 'rotate(180deg)' } : {};

  return (
    <div className={styles.accordion}>
      <button className={styles.questionGroup} onClick={toggle} type="button">
        <div className={styles.question}>{title}</div>
        <div className={styles.arrow} style={rotateStyles}>
          <ArrowDownIcon color="#514495" />
        </div>
      </button>
      {open && <div className={styles.answer}>{desc}</div>}
    </div>
  );
};

const Faq: FC<{ data: IFaq }> = ({ data }) => (
  <div className={styles.wrap}>
    <div className={styles.title}>FAQ</div>
    <div className={styles.titleMobile}>Частые вопросы</div>
    <div className={styles.questions}>
      {data.map((item) => (
        <Accordion key={item.title} title={item.title} desc={item.desc} />
      ))}
    </div>
  </div>
);

export default Faq;
