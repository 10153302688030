import React, { FC } from 'react';
import classNames from 'classnames';
import styles from './styles.module.scss';
import root from '../../styles.module.scss';
import { Breadcrumbs } from '../../../../common/Breadcrumbs';
import { BackendButton } from '../../components/BackendButton';
import { IBackendHeader } from '../../../../../queries/directions/backend/types';
import { formatPhone } from '../../../../../utils/formatPhone';

interface IProps {
  data: IBackendHeader;
  title: string;
}

const BackendHeader: FC<IProps> = ({ title, data }) => {
  const scrollToForm = () => {
    const formElement = document.getElementById('form');
    // eslint-disable-next-line no-unused-expressions
    formElement?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <div className={styles.wrap}>
      <div className={classNames(root.breadcrumbs)}>
        <Breadcrumbs title={title} />
        <div className={styles.contacts}>
          <div>
            <a href={`tel:${data?.phone}`}>{formatPhone(data?.phone)}</a>
          </div>
          <div>
            <a href={`mailto:${data?.email}`}>{data?.email}</a>
          </div>
        </div>
      </div>
      <div className={styles.line} />
      <div className={styles.contentWrap}>
        <div className={styles.content}>
          <h1 className={styles.title}>{data?.title}</h1>
          <p className={styles.desc}>{data?.desc}</p>
          <BackendButton onClick={scrollToForm}>{data?.button}</BackendButton>
          <div className={styles.arrowIcon}>
            <img
              src="/images/directions/backend/arrow_down.svg"
              alt="arrow down"
            />
          </div>
        </div>
        <div className={styles.image}>
          <img src="/images/directions/backend/backend.png" alt={title} />
        </div>
      </div>
    </div>
  );
};

export { BackendHeader };
