import React, { FC, useEffect } from 'react';
import styles from './styles.module.scss';
import { STACK_TEHCNOLOGIES_ICONS } from '../../../../common/icons/StackTechnologiesIcons';
import { horizontalScroll } from '../../../../utils/horizontalScroll';

export type IStackTechnology = {
  title: string;
  icon: string;
  stacks: {
    title: string;
    cards: string[];
  }[];
};

const StackTechnology: FC<{ data: IStackTechnology }> = ({ data }) => {
  useEffect(() => {
    data.stacks.map((stack) => horizontalScroll(stack.title));
  }, []);

  return (
    <section className={styles.wrap}>
      <div className={styles.titleWrap}>
        <div className={styles.iconWrap}>
          <img src={data.icon} alt="stack" width="100%" height="100%" />
        </div>
        <h2>{data.title}</h2>
      </div>
      <div className={styles.listWrap}>
        {data.stacks.map((stack) => (
          <div key={stack.title} className={styles.list}>
            <div className={styles.title}>
              <div className={styles.dot} />
              <h3>{stack.title}</h3>
            </div>
            <div className={styles.cards} id={stack.title}>
              {stack.cards.map((card) => (
                <div key={card} className={styles.card}>
                  <span />
                  <div>{STACK_TEHCNOLOGIES_ICONS[card]}</div>
                  <div className={styles.cardTitle}>{card}</div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default StackTechnology;
